import React from 'react'
import Fade from 'react-reveal/Fade'

import SEO from '../components/seo'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className='content'>
      <div className='content__inner'>
        <Fade bottom distance='40px'>
          <h1>Not Found</h1>
          <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
        </Fade>
      </div>
    </div>
  </>
)

export default NotFoundPage
